import { useRef, useState, useEffect } from 'react'
import { isEqual } from 'lodash'
import { UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment } from 'generated/graphql'
import shuffleList from '../modules/shuffleList'

/**
 * 공고형 광고에서 최초 1회만 셔플 로직을 실행합니다.
 * 스크랩 등의 동작으로 리렌더링이 발생할 때 한 번 셔플된 리스트의 순서에 맞춰 리턴합니다.
 * @param data - 셔플할 공고형 광고 리스트
 * @returns 셔플된 리스트
 */
const useShuffleActivityAdList = <
  TData extends UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
>(
  data: TData[],
) => {
  const originalDataRef = useRef<TData[] | null>(null)
  const [shuffledList, setShuffledList] = useState<TData[]>([])

  useEffect(() => {
    if (!data.length) {
      return
    }

    if (!originalDataRef.current) {
      originalDataRef.current = data
      setShuffledList(shuffleList<TData>(data))
      return
    }

    const originalIds = originalDataRef.current.map((item) => item.activity.id)
    const newIds = data.map((item) => item.activity.id)
    const isSameData = isEqual(originalIds, newIds)

    if (isSameData) {
      // 동일한 광고 리스트일 경우 새 데이터를 기존 셔플 데이터의 순서에 맞춰 리턴
      setShuffledList((prev) => {
        return prev.map((item) => {
          return data.filter(
            (newItem) => newItem.activity.id === item.activity.id,
          )[0]
        })
      })
    } else {
      originalDataRef.current = data
      setShuffledList(shuffleList<TData>(data))
    }
  }, [data])

  return { shuffledList }
}

export default useShuffleActivityAdList
