import styled from 'styled-components'
import Link from 'components/Link'

const GsatBanner = () => {
  return (
    <StyledWrapper>
      <Link href="/prediction/gsat2025-1" passHref>
        <img
          src="static/images/app-banner/gsat-banner.webp"
          alt="삼성 GSAT 풀서비스"
          width={160}
          height={330}
        />
      </Link>
    </StyledWrapper>
  )
}

export default GsatBanner

const StyledWrapper = styled.div`
  width: 160px;
  height: 330px;
  position: absolute;
  left: calc(100% + 20px);
  top: 0;

  & > a {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: block;
    overflow: hidden;
  }
`
